<template>
  <div class="mx-auto">
    <audio autoplay muted loop :ref="refWithPrefix">
      <source :src="src" type="audio/mpeg" />
      Your browser does not support the audio tag.
    </audio>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
    },
    reference: {
      type: String,
    },
  },
  data() {
    return {
      playing: false,
      autoplayPrevented: false,
    };
  },
  computed: {
    refWithPrefix() {
      return `ae-${this.reference}`;
    },
  },
  methods: {
    playMedia() {
      //   this.$refs[this.refWithPrefix].load();
      let promise = this.$refs[this.refWithPrefix].play();
      if (promise !== undefined) {
        promise
          /* eslint-disable no-unused-vars */
          .then((_) => {
            /* eslint-enable no-unused-vars */
            // Autoplay started!
            // this.autoplayPrevent = false;
            this.playing = true;
            console.log("Background Autoplaying..");
          })
          .catch((error) => {
            // Autoplay was prevented.
            // Show a "Play" button so that user can start playback.
            console.log("Audio Background Element: Autoplay was prevented");
            console.log(error);
            this.autoplayPrevented = true;
            this.playing = false;
          });
      } else {
        console.log("audio background promise undefined");
      }
    },
    pauseMedia() {
      if (!this.$refs[this.refWithPrefix].paused) {
        this.$refs[this.refWithPrefix].pause();
        this.playing = false;
      }
    },
    handlePlayAndPause() {
      if (this.$refs[this.refWithPrefix].paused) {
        this.playMedia();
        this.playing = true;
      } else {
        this.$refs[this.refWithPrefix].pause();
        this.playing = false;
      }
    },
  },
};
</script>