<template>
  <div>
    <full-page ref="fullpage" :options="options" id="fullpage">
      <!-- 1_1_1.1.1 Story image -->
      <fullpage-section :wrapperClasses="['bg-blue']">
        <div class="flex flex-col h-screen-without-header relative">
          <h3 class="text-center pt-0 pb-4 bg-orange">Ana</h3>
          <div class="items-center bg-orange">
            <div class="mb-5 w-1/3 mx-auto">
              <svg1111-clock5-am
                ref="svg1111clock5am"
                :key="svg1111Clock5AmVal"
              ></svg1111-clock5-am>
            </div>
          </div>
          <InitialLetterParagraph
            text="5am (COT) /12am (CET): A hard-won peace<br>Before I wake up the little ones, I go out to the fields briefly, then do some work around the house. Now, I can cook breakfast for my niños and send them off to school every morning."
            :background="['bg-blue']"
            :parentBackground="'bg-blue'"
          ></InitialLetterParagraph>
          <audio-element
            class="mt-5"
            ref="audio11111"
            :reference="'audio11111'"
            :src="
              require('../../assets/audio/stories/story_1/Sounds_Voice-Overs/Audio Ana_1.1.1.wav')
            "
          ></audio-element>
          <img
            src="~@/assets/images/stories/story_1/Story_1_1.1.1/Story1_1.1.1_1.svg"
            alt="watches her children leave"
            class="mb-0"
          />
        </div>
      </fullpage-section>
      <!-- 1_1_1.1.1 -->
      <!-- 1_1_1.1.1 Watches her children leave -->
      <fullpage-section :wrapperClasses="['bg-blue-200']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="Once they are gone, I get back to work. It makes me happy watching them leave for school every day. A couple of years ago, it was very different…"
            :background="['bg-orange']"
            :parentBackground="'bg-blue-200'"
          ></InitialLetterParagraph>
          <img
            src="~@/assets/images/stories/story_1/Story_1_1.1.1/Story1_1.1.1_2.svg"
            alt="watches her children leave"
            class="mb-0"
          />
        </div>
      </fullpage-section>
      <!-- 1_1_1.1.1 -->
      <!-- 1_1_1.1.2 Video -->
      <fullpage-section :wrapperClasses="['bg-green-200']">
        <div class="flex h-screen justify-center items-center">
          <div class="text-center">
            <video-element
              ref="video11112"
              :reference="'video11112'"
              :src="
                require('../../assets/images/stories/story_1/Story_1_1.1.2/1.1.2_1.2.4_AnimationSnippet_v02.mp4')
              "
              @videoplaying="handleBgAudio"
            ></video-element>
          </div>
        </div>
      </fullpage-section>
      <!-- 1_1_1.1.2 -->
      <!-- 1_1_1.1.3 Ana working on the field -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="After my husband died my situation got even worse. I had to work more to feed my niños. The coca plantations were 9 hours away, hidden deep in the jungle. I had to walk there and,  stayed there for half the month. "
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <audio-element
            ref="audio11113"
            :reference="'audio11113'"
            :src="
              require('../../assets/audio/stories/story_1/Sounds_Voice-Overs/Audio Ana_1.1.3.wav')
            "
          ></audio-element>
          <div>
            <img
              src="~@/assets/images/stories/story_1/Story_1_1.1.3/Story_1_1.1.3.svg"
              alt="Ana working on the field"
              class="-mb-10 transform scale-200 sm:scale-100"
            />
          </div>
          <InitialLetterParagraph
            text="It never became my home. Home was where my children were. Every night I prayed for them."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
        </div>
      </fullpage-section>
      <!-- 1_1_1.1.3 -->
      <!-- 1_1_1.1.4 Map -->
      <fullpage-section :wrapperClasses="['bg-green-200']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="Like Ana, millions of peasants around the world face the same fate. Especially in countries where coca and opium are cultivated, they depend on the illicit cultivation of drug crops to survive."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <div class="bg-green-200">
            <img
              src="~@/assets/images/stories/story_1/Story_1_1.1.4/story1_1.1.4.svg"
              alt="World map"
              class="w-3/4 mx-auto"
            />
            <p class="p-4">
              <a
                target="_blank"
                alt="Link to PDF document"
                href="https://www.unodc.org/documents/crop-monitoring/Bolivia/Bolivia_Coca_Survey_Fact_sheet_Executive_Summary_2020.pdf"
                >Bolivia: 29,400 ha coca cultivation in Bolivia in 2020 (rounded
                to nearest hundred), up +15% from the previous</a
              >
            </p>
            <p class="p-4">
              <a
                target="_blank"
                alt="Link to PDF document"
                href="https://www.unodc.org/documents/crop-monitoring/Bolivia/Bolivia_Coca_Survey_Fact_sheet_Executive_Summary_2020.pdf"
                >Colombia: 143,000 ha coca cultivation area in 2020 (rounded to
                the nearest thousand), down 7% from the previous year</a
              >
            </p>
            <p class="p-4">
              <a
                target="_blank"
                alt="Link to PDF document"
                href="https://www.unodc.org/documents/crop-monitoring/Myanmar/Myanmar_Opium_survey_2020.pdf"
                >Myanmar: 29,500 ha total opium poppy cultivation in 2020, down
                -11% from the previous year</a
              >
            </p>
            <p class="p-4">
              <a
                target="_blank"
                alt="Link to PDF document"
                href="https://www.unodc.org/documents/crop-monitoring/Afghanistan/20210503_Executive_summary_Opium_Survey_2020_SMALL.pdf"
                >Afghanistan: 224,000 ha opium poppy cultivation in 2020, up
                +37% from the previous year</a
              >
            </p>
          </div>
        </div>
      </fullpage-section>
      <!-- 1_1_1.1.4 -->
      <!-- 1_1_1.1.5 Poorly built long roads to markets -->
      <fullpage-section :wrapperClasses="['bg-blue']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="In most cases, people cultivate illicit drug crops because they live in areas with development deficits."
            :background="['bg-orange']"
            :parentBackground="'bg-blue'"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="These frequently remote regions have high rates of poverty and weak or absent state institutions. They have no electricity, insufficient water supply and weak transport infrastructure. The next market is often far away. The lack of educational opportunities and institutions in the area hinders people from pursuing any livelihoods other than farming. Armed conflicts, crime and violence are prevalent."
            :background="['bg-blue']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="This precarious situation of the local population makes them vulnerable to<br> illicit drug economies."
            :background="['bg-orange']"
            :parentBackground="'bg-blue'"
          ></InitialLetterParagraph>
          <div class="items-baseline">
            <img
              src="~@/assets/images/stories/story_1/Story_1_1.1.5/Story_1_1.1.5.svg"
              alt="Story image"
              class="mb-0 transform scale-150 sm:scale-100"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 1_1_1.1.5 -->
      <!-- 1_1_1.1.6 Only text -->
      <fullpage-section :wrapperClasses="['bg-blue']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="For many this is the only source of income to ensure the livelihood of their families. The criminal groups that control the regional drug trade exploit the situation. This creates a vicious cycle of dependency and violence."
            :background="['bg-orange']"
            :parentBackground="'bg-blue'"
          ></InitialLetterParagraph>
        </div>
      </fullpage-section>
      <!-- 1_1_1.1.6 -->
      <!-- 1_1_1.1.7 Video -->
      <fullpage-section :wrapperClasses="['bg-green-200']">
        <div class="flex h-screen justify-center items-center">
          <div class="text-center">
            <!-- ⬅️ THIS DIV WILL BE CENTERED -->
            <video-element
              ref="video11117"
              :reference="'video11117'"
              :src="
                require('../../assets/images/stories/story_1/Story_1_1.1.7/film 1.1.7.mp4')
              "
              @videoplaying="handleBgAudio"
            ></video-element>
          </div>
        </div>
      </fullpage-section>
      <!-- 1_1_1.1.7 -->
      <!-- 1_1_1.1.8 Plot of land and a house -->
      <fullpage-section :wrapperClasses="['bg-blue']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="All this became reality for Ana’s family because of the Alternative Development (AD) programme. When they were approached by the government, they were promised the formalisation of the piece of land they were living on."
            :background="['bg-orange']"
            :parentBackground="'bg-blue'"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="After receiving technical training, seedlings and fertilisers and support in setting up their new fields, they were able to diversify their household’s income. They started to grow both income crops, such as cocoa and coffee, and subsistence crops, such as papayas, vegetables or bananas."
            :background="['bg-blue']"
          ></InitialLetterParagraph>
          <div class="items-baseline">
            <img
              src="~@/assets/images/stories/story_1/Story_1_1.1.8/Story_1_1.1.8.svg"
              alt="Story image"
              class="-mb-20 sm:-mb-0 transform scale-200 sm:scale-100"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 1_1_1.1.8 -->
      <!-- 1_1_1.1.9 At the field -->
      <fullpage-section :wrapperClasses="['bg-blue']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="Ana: I met some women in similar situations and we came together and formed a cooperative. Luckily we were smart and invested in some long-term crops, such as cocoa and coffee. They bring us a stable income, whilst the other crops bring us food. "
            :background="['bg-orange']"
            :parentBackground="'bg-blue'"
          ></InitialLetterParagraph>
          <audio-element
            ref="audio11119"
            :reference="'audio11119'"
            :src="
              require('../../assets/audio/stories/story_1/Sounds_Voice-Overs/Audio Ana_1.1.9.wav')
            "
          ></audio-element>
          <div class="items-baseline">
            <img
              src="~@/assets/images/stories/story_1/Story_1_1.1.9/1.1.9_1.svg"
              alt="Story image"
              class="mb-0 transform scale-125 sm:scale-100"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 1_1_1.1.9 -->
      <!-- 1_1_1.1.10 Close-up to the field -->
      <fullpage-section :wrapperClasses="['bg-blue']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="Cash crops, such as cocoa or coffee, are grown in the shade of subsistence crops, such as bananas or tomatoes. It's a lot of work and requires patience. But it pays off. A stable and self-sufficient life lies ahead."
            :background="['bg-orange']"
            :parentBackground="'bg-blue'"
          ></InitialLetterParagraph>
          <div class="items-center">
            <!-- Needs close-up image -->
            <img
              src="~@/assets/images/stories/story_1/Story_1_1.1.9/1.1.9_1.svg"
              alt="Story image"
              class="mb-0 transform scale-200 sm:scale-100"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 1_1_1.1.10 -->
      <!-- 1_1_1.1.11 Infographic -->
      <fullpage-section :wrapperClasses="['bg-white']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="Having different sources of income allows for a gradual transition away from illicit drug crops. Overtime, people can acquire a bigger share of the value chain. Take coffee, for example."
            :background="['bg-blue']"
          ></InitialLetterParagraph>
          <div class="items-center bg-white">
            <img
              src="~@/assets/images/stories/story_1/story_1_1.1.11/story1.1.11_A.svg"
              alt="Story image"
              class="mx-auto p-5"
            />
          </div>
        </div>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-white']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="Having different sources of income allows for a gradual transition away from illicit drug crops. Overtime, people can acquire a bigger share of the value chain. Take coffee, for example."
            :background="['bg-blue']"
          ></InitialLetterParagraph>
          <div class="items-center bg-white">
            <img
              src="~@/assets/images/stories/story_1/story_1_1.1.11/story1.1.11_B.svg"
              alt="Story image"
              class="mx-auto p-5"
            />
          </div>
        </div>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-white']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="Having different sources of income allows for a gradual transition away from illicit drug crops. Overtime, people can acquire a bigger share of the value chain. Take coffee, for example."
            :background="['bg-blue']"
          ></InitialLetterParagraph>
          <div class="items-center bg-white">
            <img
              src="~@/assets/images/stories/story_1/story_1_1.1.11/story1.1.11_C.svg"
              alt="Story image"
              class="mx-auto p-5"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 1_1_1.1.11 -->
      <!-- 1_1_1.1.13 tomato, banana, coffee and cocoa appear. Same picture of diversified income as before. Roads, a school, a hospital appear -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="Livelihood diversification is key to AD. It ensures a stable income because farmers no longer depend on just one crop."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="But AD goes further than that. It is a holistic and integrated approach to development that focuses on  the needs of the people and strengthens communities."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="To achieve long-term success, different dimensions of AD need to be taken into account."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <div class="items-center">
            <img
              src="~@/assets/images/stories/story_1/Story_1_1.1.13/Story_1_1.1.13_1.svg"
              alt="Story image"
              class="mb-0 transform scale-150 sm:scale-100"
            />
          </div>
        </div>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="Livelihood diversification is key to AD. It ensures a stable income because farmers no longer depend on just one crop."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="But AD goes further than that. It is a holistic and integrated approach to development that centres the needs of the people and strengthens communities."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="To achieve long-term success, different dimensions of AD need to be taken into account."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <div class="items-center">
            <img
              src="~@/assets/images/stories/story_1/Story_1_1.1.13/Story_1_1.1.13_2.svg"
              alt="Story image"
              class="mb-0 transform scale-150 sm:scale-100"
            />
          </div>
        </div>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="Livelihood diversification is key to AD. It ensures a stable income because farmers no longer depend on just one crop."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="But AD goes further than that. It is a holistic and integrated approach to development that centres the needs of the people and strengthens communities."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="To achieve long-term success, different dimensions of AD need to be taken into account."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <div class="items-center">
            <img
              src="~@/assets/images/stories/story_1/Story_1_1.1.13/Story_1_1.1.13_3.svg"
              alt="Story image"
              class="mb-0 transform scale-150 sm:scale-100"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 1_1_1.1.13 -->
      <!-- 1_1_1.1.14 Puzzle Text -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="These dimensions need to be considered together for an intervention to be successful. For example, proper sequencing is key. Proper sequencing means that people need to have a licit income before the coca fields are destroyed. Regional development, such as  creating alternative legal incomes, has to be promoted before a harsh response to drug cultivation can be pursued, otherwise the livelihoods of many small farmers are threatened."
          ></InitialLetterParagraph>
        </div>
      </fullpage-section>
      <!-- 1_1_1.1.14 -->
      <!-- 1_1_1.1.15 Film on AD produced by edeos -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex h-screen justify-center items-center">
          <div class="text-center">
            <InitialLetterParagraph
              text="The holistic approach to alternative development"
              :background="['bg-blue']"
              :parentBackground="'bg-blue'"
            ></InitialLetterParagraph>
            <!-- ⬅️ THIS DIV WILL BE CENTERED -->
            <video-element
              ref="video1115"
              :reference="'video1115'"
              :src="
                require('../../assets/images/stories/story_1/Story_1_1.1.5/1.1.15.mp4')
              "
              @videoplaying="handleBgAudio"
            ></video-element>
          </div>
        </div>
      </fullpage-section>
      <!-- 1_1_1.1.15 -->
      <!-- 1_1_1.1.16 After the Drug Wars | LSE Research -->
      <fullpage-section :wrapperClasses="['bg-green-200']">
        <div class="aspect-w-16 aspect-h-9">
          <iframe
            src="https://www.youtube.com/embed/Qx_GlcMwaec?start=200"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </fullpage-section>
      <!-- 1_1_1.1.16 -->
      <!-- 1_1_1.1.17 Policy icon appear again, Group of people appears in the policy icon, A field of growing plants and vital environment -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="In order to harvest the fruits of AD, government policies need to drive this transformation. The EU Drugs Strategy takes a step in the right direction"
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <div class="items-center">
            <img
              src="~@/assets/images/stories/story_1/Story_1_1.1.17/Story_1_1.1.17_1.svg"
              alt="Story image"
              class="mb-10 w-1/2 mx-auto transform scale-125 sm:scale-100"
            />
          </div>
        </div>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="In order to harvest the fruits of AD, government policies need to drive this transformation. The EU Drugs Strategy takes a step in the right direction"
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="By making sure the human rights of those affected by the illicit drug economy are at the core of AD. This includes the right to an adequate standard of living, including adequate food, clothing and housing, the right to health, the right to education, and non-discrimination."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <div class="items-center">
            <img
              src="~@/assets/images/stories/story_1/Story_1_1.1.17/Story_1_1.1.17_2.svg"
              alt="Story image"
              class="transform w-1/2 mx-auto scale-125 mb-10 sm:scale-100"
            />
          </div>
        </div>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="In order to harvest the fruits of AD, government policies need to drive this transformation. The EU Drugs Strategy takes a step in the right direction"
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="By making sure the human rights of those affected by the illicit drug economy are at the core of AD. This includes the right to an adequate standard of living, including adequate food, clothing and housing, the right to health, the right to education, and non-discrimination."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="For many small holder farmers and their families, AD is the way out of poverty, oppression and dependency."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <div class="items-center">
            <img
              src="~@/assets/images/stories/story_1/Story_1_1.1.17/Story_1_1.1.17_3.svg"
              alt="Story image"
              class="mb-0 transform scale-125 sm:scale-100 w-1/3 sm:w-1 mx-auto"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 1_1_1.1.17 -->
      <!-- 1_1_1.1.18 -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="With a human-centred development approach…<br><br>…a more self-determined life lies ahead."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
        </div>
      </fullpage-section>
      <!-- 1_1_1.1.18 -->
      <!-- 1_1_1.2.1 Story image Children are back from school, she has cooked them something to eat. -->
      <fullpage-section :wrapperClasses="['bg-blue']">
        <div class="flex flex-col h-screen-without-header">
          <div class="mt-4 w-1/3 mx-auto">
            <svg1121-clock4-pm
              ref="svg1121clock4pm"
              :key="svg1121Clock4PmVal"
            ></svg1121-clock4-pm>
          </div>
          <InitialLetterParagraph
            text="4 pm (COT) /11 pm (CET) The Alternative Development Programme<br><br>Seeing my children care-free makes me happy. I still remember how scared I was when we were approached to participate in the AD programme. "
          ></InitialLetterParagraph>
          <audio-element
            ref="audio11121"
            :reference="'audio11121'"
            :src="
              require('../../assets/audio/stories/story_1/Sounds_Voice-Overs/Audio Ana_1.2.1.wav')
            "
          ></audio-element>
          <div class="items-baseline">
            <img
              src="~@/assets/images/stories/story_1/Story_1_1.2.1/Story_1_1.2.1.svg"
              alt="Story image"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 1_1_1.2.1 -->
      <!-- 1_1_1.2.2 Ana working, keeping her head down and turning away from people. -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex h-screen justify-center items-center">
          <div class="text-center">
            <!-- ⬅️ THIS DIV WILL BE CENTERED -->
            <video-element
              ref="video11122"
              :reference="'video11122'"
              :src="
                require('../../assets/images/stories/story_1/Story_1_1.2.2/Film_1.2.2.mp4')
              "
              @videoplaying="handleBgAudio"
            ></video-element>
          </div>
        </div>
      </fullpage-section>
      <!-- 1_1_1.2.2 -->
      <!-- 1_1_1.2.3 Policy icon with humans in the middle -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="Alternative Development (AD) focuses on the people that are negatively affected by the illicit drug trade. Not taking into account the local context can lead to severe social impacts."
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="For example, measures must not solely focus on reducing drug cultivation, or on substituting illicit crops with legal alternatives."
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="Exiting the illicit drug trade may be dangerous for farmers and their families. For a successful transition, alternative licit incomes must exist, and the food supply must be secured first."
          ></InitialLetterParagraph>
          <div class="items-center">
            <img
              src="~@/assets/images/stories/story_1/Story_1_1.2.3/Story_1_1.2.3_1.svg"
              alt="Story image"
              class="mb-0 transform scale-125 sm:scale-100"
            />
          </div>
        </div>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="With the effects of climate change set to become more drastic over the next decades, AD further aims to think of the social and environmental impacts of interventions together.<br><br>Drug economies have a massive negative effect on the environment.<br><br>Common practices include: "
            :background="['bg-blue']"
            :parentBackground="'bg-orange'"
          ></InitialLetterParagraph>
          <ul class="list-decimal pl-8">
            <li>
              Clearing forest for illegal fields, roads and landing strips,
            </li>
            <li>monoculture farming,</li>
            <li>using fertilisers and chemicals,</li>
            <li>
              dumping chemical waste from drug production into forests and
              rivers.
            </li>
          </ul>
          <div class="items-center">
            <img
              src="~@/assets/images/stories/story_1/Story_1_1.2.3/Story_1.2.3_2.svg"
              alt="Story image"
              class="mb-0 transform scale-125 sm:scale-100"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 1_1_1.2.3 -->
      <!-- 1_1_1.2.4 Ana Overlooking her fields -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex h-screen justify-center items-center">
          <div class="text-center">
            <InitialLetterParagraph
              text="6 pm (COT) /1 am (CET)  New livelihoods, new beginnings"
              :background="['bg-blue']"
              :parentBackground="'bg-blue'"
            ></InitialLetterParagraph>
            <!-- ⬅️ THIS DIV WILL BE CENTERED -->
            <video-element
              ref="video11124"
              :reference="'video11124'"
              :src="
                require('../../assets/images/stories/story_1/Story_1_1.2.4/1.2.4_AnimationSnippet_v02.mp4')
              "
              @videoplaying="handleBgAudio"
            ></video-element>
          </div>
        </div>
      </fullpage-section>
      <!-- 1_1_1.2.4 -->
      <!-- 1_1_1.2.5 Dead fields, we see workers working cutting further trees, preparing the next field for coca crops. -->
      <fullpage-section :wrapperClasses="['bg-blue']">
        <div
          class="flex flex-col h-screen-without-header justify-between relative"
        >
          <InitialLetterParagraph
            text="You see, the coca fields were often eradicated by the government. Or just used up. We moved further and further into the jungle. Sometimes family members of mine were hired to cut trees illegally for new coca fields or dirt roads. It made my heart heavy to see how the land was being treated. But what can you do? You need to continue making an income, you have mouths to feed, so you always go back."
          ></InitialLetterParagraph>
          <audio-element
            ref="audio11125"
            :reference="'audio11125'"
            :src="
              require('../../assets/audio/stories/story_1/Sounds_Voice-Overs/Audio Ana_1.2.5.wav')
            "
          ></audio-element>
          <div class="absolute w-full -z-10 bottom-0">
            <img
              src="~@/assets/images/stories/story_1/Story_1_1.2.5/Story_1_1.2.5_1.svg"
              alt="Story image"
              class=""
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 1_1_1.2.5 -->
      <!-- 1_1_1.2.18 -->
      <fullpage-section :wrapperClasses="['bg-blue']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <div class="items-baseline">
            <img
              src="~@/assets/images/stories/story_1/Story_1_graphic1.2.18/Graphic 1.2.18_backA.svg"
              alt="Story image"
              class="mb-0 transform scale-75 sm:scale-75"
            />
          </div>
        </div>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-blue']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <div class="items-baseline">
            <img
              src="~@/assets/images/stories/story_1/Story_1_graphic1.2.18/Graphic 1.2.18_backB.svg"
              alt="Story image"
              class="mb-0 transform scale-75 sm:scale-75"
            />
          </div>
        </div>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-blue']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <div class="items-baseline">
            <img
              src="~@/assets/images/stories/story_1/Story_1_graphic1.2.18/Graphic 1.2.18_backC.svg"
              alt="Story image"
              class="mb-0 transform scale-75 sm:scale-75"
            />
          </div>
        </div>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-blue']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <div class="items-baseline">
            <img
              src="~@/assets/images/stories/story_1/Story_1_graphic1.2.18/Graphic 1.2.18_front.svg"
              alt="Story image"
              class="mb-0 transform scale-75 sm:scale-75"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 1_1_1.2.18 -->
      <!-- 1_1_1.2.6 die 3 Alternative Development Grafiken (Story_1_1.2.6) können raus. Es wird hierfür auch keine Ersatzgrafiken geben. -->
      <!-- 1_1_1.2.6 -->
      <!-- 1_1_1.2.7 Animated video (produced by edeos) -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex h-screen justify-center items-center">
          <div class="text-center">
            <!-- ⬅️ THIS DIV WILL BE CENTERED -->
            <video-element
              ref="video1127"
              :reference="'video1127'"
              :src="
                require('../../assets/images/stories/story_1/Story_1_1.2.7/1.2.7.mp4')
              "
              @videoplaying="handleBgAudio"
            ></video-element>
          </div>
        </div>
      </fullpage-section>
      <!-- 1_1_1.2.7 -->
      <!-- 1_1_1.2.8 Contaminated soil. Plants dying off. Empty hands. -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="AD interventions can bring together development and environmental goals. If well-designed, they promote sustainable sources of income while protecting the environment."
            :background="['bg-blue']"
            :parentBackground="'bg-orange'"
          ></InitialLetterParagraph>
          <div class="items-baseline">
            <img
              src="~@/assets/images/stories/story_1/Story_1_1.2.8/Story_1_1.2.8_1.svg"
              alt="Story image"
              class="mb-0 transform scale-200 sm:scale-100"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 1_1_1.2.8 -->
      <!-- 1_1_1.2.9 Animated video (produced by edeos) -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex h-screen justify-center items-center">
          <div class="text-center">
            <InitialLetterParagraph
              text="Human rights and alternative development"
              :background="['bg-blue']"
              :parentBackground="'bg-blue'"
            ></InitialLetterParagraph>
            <!-- ⬅️ THIS DIV WILL BE CENTERED -->
            <video-element
              ref="video1129"
              :reference="'video1129'"
              :src="
                require('../../assets/images/stories/story_1/Story_1_1.2.9/1.2.9.mp4')
              "
              @videoplaying="handleBgAudio"
            ></video-element>
            <p class="text-left pl-8">
              The destruction of the environment is also a major cause of food
              insecurity. Deforestation destroys the natural environment and its
              biodiversity, making it difficult to impossible to work the land.
              Monocultures leach the soil and deplete groundwater reserves until
              nothing can be grown. As so often, it is women who carry the
              greatest burden. They are more affected by the possible negative
              impacts of harsh responses to drug economies. Women are often the
              main food provider of the family, making access to food not only a
              human right’s issue, but a women’s rights issue.
            </p>
          </div>
        </div>
      </fullpage-section>
      <!-- 1_1_1.2.9 -->
      <!-- 1_1_1.2.10 Illustration of Ana -->
      <fullpage-section :wrapperClasses="['bg-blue']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="After my husband was killed, I felt so much weight on my shoulders. When I was approached by the government programme I thought: but who will feed my children? Yes, I’m not at home half of the month, but at least they can eat. All I ever cared about was feeding my children."
            :background="['bg-orange']"
            :parentBackground="'bg-blue'"
          ></InitialLetterParagraph>
          <audio-element
            ref="audio111210"
            :reference="'audio111210'"
            :src="
              require('../../assets/audio/stories/story_1/Sounds_Voice-Overs/Audio Ana_1.2.10.wav')
            "
          ></audio-element>
          <div class="items-baseline bg-blue">
            <img
              src="~@/assets/images/stories/story_1/Story_1_1.2.10/Story_1_1.2.10.svg"
              alt="Story image"
              class="w-2/5 mx-auto"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 1_1_1.2.10 -->
      <!-- 1_1_1.2.10 2 Illustration of Ana -->
      <fullpage-section :wrapperClasses="['bg-blue']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="It took a while for me to trust them. But it has been a good decision. Once I joined the alternative development programme, together with some other women, we decided to pool our resources and form a cooperative"
            :background="['bg-blue']"
          ></InitialLetterParagraph>
          <div class="items-baseline">
            <img
              src="~@/assets/images/stories/story_1/Story_1_1.2.11/Story_1.2.11_1.svg"
              alt="Story image"
              class="mx-auto"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 1_1_1.2.10 2 -->
      <!-- 1_1_1.2.11 -->
      <fullpage-section :wrapperClasses="['bg-blue']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="On top of being the main food provider, women are often the main carer of older family members. They carry the weight of the community but get paid less than their male counterparts.<br><br>Women are more prone to build up licit livelihoods. They are the key change agents in drug crop growing areas."
            :background="['bg-blue']"
          ></InitialLetterParagraph>
          <div class="items-baseline">
            <img
              src="~@/assets/images/stories/story_1/Story_1_1.2.11/Story_1.2.11_1.svg"
              alt="Story image"
              class="mx-auto"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 1_1_1.2.11 -->
      <!-- 1_1_1.1.12 Women’s role -->
      <fullpage-section :wrapperClasses="['bg-green-200']">
        <div class="aspect-w-16 aspect-h-9">
          <iframe
            src="https://www.youtube.com/embed/BrERZjvxI4s?start=81&end=102"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </fullpage-section>
      <!-- 1_1_1.1.12 -->
      <!-- 1_1_1.1.12 2 Women’s role -->
      <fullpage-section :wrapperClasses="['bg-green-200']">
        <div class="aspect-w-16 aspect-h-9">
          <!-- ⬅️ THIS DIV WILL BE CENTERED -->
          <iframe
            src="https://www.youtube.com/embed/uRqGvoYWCUw?start=221&end=259"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </fullpage-section>
      <!-- 1_1_1.1.12 2 -->
      <!-- 1_1_1.2.13 -->
      <fullpage-section :wrapperClasses="['bg-blue']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="A successful development programme places women and their needs at its centre. It must advocate for reform on all levels: agricultural, institutional, legal and economic.<br><br>A development programme will only succeed by actively considering women’s rights."
            :background="['bg-blue']"
          ></InitialLetterParagraph>
        </div>
      </fullpage-section>
      <!-- 1_1_1.2.13 -->
      <!-- 1_1_1.2.14 -->
      <fullpage-section :wrapperClasses="['bg-blue']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="Take land formalisation, for example."
            :background="['bg-blue']"
          ></InitialLetterParagraph>
        </div>
      </fullpage-section>
      <!-- 1_1_1.2.14 -->
      <!-- 1_1_1.2.15 -->
      <fullpage-section :wrapperClasses="['bg-green-200']">
        <InitialLetterParagraph
          text="Land formalisation"
          :background="['bg-blue']"
          :parentBackground="'bg-blue'"
        ></InitialLetterParagraph>
        <div class="aspect-w-16 aspect-h-9">
          <iframe
            src="https://www.youtube.com/embed/Qx_GlcMwaec?start=395&end=452"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </fullpage-section>
      <!-- 1_1_1.2.15 -->
      <!-- 1_1_1.2.16 -->
      <fullpage-section :wrapperClasses="['bg-blue']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="The ownership of land ensures a stable livelihood and the dignity of farmers. They take responsibility for sustainable management. At the same time, they can invest, take loans and act independently.<br><br>But tradition and inheritance laws make women less likely to own a plot of land. Not considering this imbalance runs the risk of re-enforcing deeply entrenched disadvantages. Women’s access to and control over land and land titles, as well as access to formal credit, must be increased.<br><br>The human-centred approach to development presents an opportunity to improve the economic, social and legal situation of women."
            :background="['bg-blue']"
          ></InitialLetterParagraph>
        </div>
      </fullpage-section>
      <!-- 1_1_1.2.16 -->
      <!-- 1_1_1.2.18 -->
      <fullpage-section :wrapperClasses="['bg-blue']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="To prevent human rights violations, the EU places three aspects at the core of its Drugs Strategy:"
            :background="['bg-blue']"
          ></InitialLetterParagraph>
          <ul class="list-decimal pl-8">
            <li>
              Non-discrimination: Services and programmes must not discriminate
              against women, they must be accessible to them and actively take
              into account their needs.
            </li>
            <li>
              Non-conditionality: For example, a reduction in coca production in
              an area is not the condition for farmers to receive aid.
            </li>
            <li>
              Proper sequencing: For example, a legal alternative income must be
              available before coca plantations are destroyed.
            </li>
          </ul>
        </div>
      </fullpage-section>
      <!-- 1_1_1.2.18 -->
      <!-- 1_1_1.2.19 -->
      <fullpage-section :wrapperClasses="['bg-blue']">
        <div class="flex flex-col h-screen-without-header justify-between">
          <InitialLetterParagraph
            text="The EU Drug Strategy 2021-2025 is a global signpost. Now it’s time to put it into practice."
            :background="['bg-blue']"
          ></InitialLetterParagraph>
        </div>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-blue']">
        <div class="flex flex-col h-screen-without-header">
          <div class="flex h-screen justify-center items-center">
            <div class="mt-4 grid grid-cols-2 gap-4">
              <router-link to="/story-kofi">
                <img
                  src="~@/assets/images/stories/intro/Intro_01_Clocks/01.Clocks_fullsize_Kofi.svg"
                  alt="story image"
                  class="w-full px-2 md:w-2/3 mx-auto"
                />
                <h3 class="text-center text-2xl pb-2">Kofi</h3>
              </router-link>
              <router-link to="/story-mila">
                <img
                  src="~@/assets/images/stories/intro/Intro_01_Clocks/01.Clocks_fullsize_Mila.svg"
                  alt="story image"
                  class="w-full px-2 md:w-2/3 mx-auto"
                />
                <h3 class="text-center text-2xl pb-2">Mila</h3>
              </router-link>
            </div>
          </div>
        </div>
      </fullpage-section>
      <!-- 1_1_1.2.19 -->
      <audio-background-element
        ref="bgaudio"
        :reference="'bgaudio'"
        :src="
          require('../../assets/audio/stories/story_1/bg_sound/ana_generel_mixdown.mp3')
        "
      ></audio-background-element>
    </full-page>
  </div>
</template>

<script>
import FullpageSection from "../FullpageSection";
import InitialLetterParagraph from "../InitialLetterParagraph";
import Svg1111Clock5Am from "../svg/Svg1111Clock5Am.vue";
import Svg1121Clock4Pm from "../svg/Svg1121Clock4Pm.vue";
import AudioElement from "../AudioElement.vue";
import VideoElement from "../VideoElement.vue";
import AudioBackgroundElement from "../AudioBackgroundElement.vue";

export default {
  name: "Story1FullpageWrapper",
  components: {
    FullpageSection,
    InitialLetterParagraph,
    Svg1111Clock5Am,
    Svg1121Clock4Pm,
    AudioElement,
    VideoElement,
    AudioBackgroundElement,
  },
  data() {
    return {
      options: {
        licenseKey: "1806D743-4E124AA5-92F132F2-321A5FA4",
        // menu: "#menu",
        // navigation: true,
        // sectionsColor: ["#41b883", "#ff5f45", "#0798ec"],
        // parallaxKey: "a29udHJvbGxmZWxkLm5ldF9kUXBjR0Z5WVd4c1lYZz1NOEE=",
        // parallax: true,
        // parallaxOptions: { percentage: 62 },
        fadingEffect: true,
        fadingEffectKey: "a29udHJvbGxmZWxkLm5ldF83WVNabUZrYVc1blJXWm1aV04wQUF2",
        paddingTop: "2.5rem",
        afterLoad: this.afterLoad,
        onLeave: this.onLeave,
      },
      svg1111Val: 0,
      svg11112Val: 0,
      svg1111Clock5AmVal: 0,
      svg1121Clock4PmVal: 0,
    };
  },
  methods: {
    goToLastSection() {
      this.$refs.fullpage.api.moveTo(37);
    },
    goToSectionFromParam(index) {
      if (index) {
        this.$refs.fullpage.api.moveTo(index);
      }
    },
    updateBodyBackground(bgColorClass) {
      this.removeBackgroundClassFromBody();
      document.body.classList.add(bgColorClass);
    },
    removeBackgroundClassFromBody() {
      document.body.classList.forEach(function (val, index, listObj) {
        if (val.startsWith("bg-")) {
          listObj.remove(val);
        }
      });
    },
    getBgClassFromSectionWithIndex(index) {
      return this.$children[0].$children[index].$options.propsData
        .wrapperClasses[0];
    },
    /* eslint-disable no-unused-vars */
    afterLoad(origin, destination, direction) {
      // console.log("'after load' origin:", origin);
      // console.log("'after load' destination:", destination);
      // console.log("'after load' direction:", direction);

      let originSectionIndex = origin.index + 1;
      let destinationSectionIndex = destination.index + 1;
      console.log("'originSectionIndex:", originSectionIndex);
      console.log("'destinationSectionIndex:", destinationSectionIndex);

      // If is intial load, do not replace section query.
      if (direction) {
        this.$router
          .replace({ query: { section: destinationSectionIndex } })
          .catch((err) => {});
      }
      switch (destinationSectionIndex) {
        case 1:
          this.$refs["audio11111"].playMedia();
          break;
        case 3:
          this.$refs["video11112"].playMedia();
          break;
        case 4:
          this.$refs["audio11113"].playMedia();
          break;
        case 8:
          this.$refs["video11117"].playMedia();
          break;
        case 10:
          this.$refs["audio11119"].playMedia();
          break;
        case 19:
          this.$refs["video1115"].playMedia();
          break;
        case 25:
          this.$refs["audio11121"].playMedia();
          break;
        case 26:
          this.$refs["video11122"].playMedia();
          break;
        case 29:
          this.$refs["video11124"].playMedia();
          break;
        case 30:
          this.$refs["audio11125"].playMedia();
          break;
        case 35:
          this.$refs["video1127"].playMedia();
          break;
        case 37:
          this.$refs["video1129"].playMedia();
          break;
        case 38:
          this.$refs["audio111210"].playMedia();
          break;

        default:
          break;
      }

      switch (originSectionIndex) {
        case 1:
          this.$refs["audio11111"].pauseMedia();
          break;
        case 3:
          this.$refs["video11112"].pauseMedia();
          break;
        case 4:
          this.$refs["audio11113"].pauseMedia();
          break;
        case 8:
          this.$refs["video11117"].pauseMedia();
          break;
        case 10:
          this.$refs["audio11119"].pauseMedia();
          break;
        case 19:
          this.$refs["video1115"].pauseMedia();
          break;
        case 25:
          this.$refs["audio11121"].pauseMedia();
          break;
        case 26:
          this.$refs["video11122"].pauseMedia();
          break;
        case 29:
          this.$refs["video11124"].pauseMedia();
          break;
        case 30:
          this.$refs["audio11125"].pauseMedia();
          break;
        case 35:
          this.$refs["video1127"].pauseMedia();
          break;
        case 37:
          this.$refs["video1129"].pauseMedia();
          break;
        case 38:
          this.$refs["audio111210"].pauseMedia();
          break;

        default:
          break;
      }
    },
    onLeave(origin, destination, direction) {
      let destinationSectionIndex = destination.index + 1;
      this.updateBodyBackground(
        this.getBgClassFromSectionWithIndex(destination.index)
      );

      switch (destinationSectionIndex) {
        case 1:
          this.svg1111Clock5AmVal = !this.svg1111Clock5AmVal;
          this.$refs["svg1111clock5am"].animate();
          break;
        case 3:
          break;
        case 4:
          break;
        case 8:
          break;
        case 10:
          break;
        case 25:
          this.svg1121Clock4PmVal = !this.svg1121Clock4PmVal;
          this.$refs["svg1121clock4pm"].animate();
          break;
        case 26:
          break;
        case 29:
          break;
        case 30:
          break;
        case 38:
          break;

        default:
          break;
      }
    },
    /* eslint-enable no-unused-vars */
    handleBgAudio(videoPlaying) {
      console.log("handleBgAudio", videoPlaying);
      if (videoPlaying) {
        this.pauseBgAudio();
      } else {
        this.playBgAudio();
      }
    },
    playBgAudio() {
      console.log("play BgAudio");
      this.$refs["bgaudio"].playMedia();
    },
    pauseBgAudio() {
      this.$refs["bgaudio"].pauseMedia();
    },
  },
  mounted() {
    if (Object.prototype.hasOwnProperty.call(this.$route.query, "section")) {
      this.goToSectionFromParam(this.$route.query.section);
    }
    let section = this.$route.query.section
      ? parseInt(this.$route.query.section) - 1
      : 1;
    this.updateBodyBackground(this.getBgClassFromSectionWithIndex(section));
    this.playBgAudio();
  },
  /* eslint-disable no-unused-vars */
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    console.info("beforeRouteLeave to", to);
    if (to.name != "StoryAna") {
      this.pauseBgAudio();
    }
    next();
  },
  /* eslint-enable no-unused-vars */
  beforeRouteUpdate(to, from, next) {
    let sectionTo;
    let sectionFrom;
    if (Object.prototype.hasOwnProperty.call(to.query, "section")) {
      sectionTo = parseInt(to.query.section);
    }
    if (Object.prototype.hasOwnProperty.call(from.query, "section")) {
      sectionFrom = parseInt(from.query.section);
    }
    if (sectionTo || sectionFrom) {
      if (sectionTo != sectionFrom) {
        console.log("sectionTo", sectionTo);
        if (!sectionTo) {
          sectionTo = 1;
        }
        this.goToSectionFromParam(sectionTo);
      }
    }
    next();
  },
};
</script>

<style>
/* .section .fp-bg {
  background-size: cover;
  background-position: center 80%;
}
.section[data-id="story1-1.1.1-1"] .fp-bg {
  background-image: url("~@/assets/images/stories/story_1/Story_1_1.1.1/Story1_1.1.1_1.svg");
}
.section[data-id="story1-1.1.1-2"] .fp-bg {
  background-image: url("~@/assets/images/stories/story_1/Story_1_1.1.1/Story1_1.1.1_2.svg");
} */
</style>
